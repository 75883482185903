<div class='section' *ngIf='_situations?.length'>
    <div class='section-header'>
        <div class='title'>{{title}}:</div>
        <!-- <div class='title' *ngIf='!searchParams.situation'>קהלי יעד:</div>
        <div class='title' *ngIf='!!searchParams.situation'>קהלי יעד נוספים:</div> -->
        <a class='clear' (activated)='searchParams[field] = []; changed()' clickOnReturn role='button' tabindex="0" [attr.aria-label]='"ניקוי כלל המסננים תחת " + title'>ניקוי</a>
    </div>
    <div class='tags'>
        <div *ngFor='let situation of removeDuplicates(_situations).slice(0, num_situations)'>
            <app-search-filter-checkbox
                [item]='situation'
                [current]='searchParams[field] || []'
                (changed)='toggled($event, situation)'
            ></app-search-filter-checkbox>
        </div>
        <app-search-filters-more-button *ngIf='removeDuplicates(_situations).length > num_situations' [count]='removeDuplicates(_situations).length - num_situations' [style]='"situation"' #moreSituations></app-search-filters-more-button>
        <ng-container *ngIf='!!(moreSituations?.expanded)'>
            <div *ngFor='let situation of removeDuplicates(_situations).slice(num_situations)'>
                <app-search-filter-checkbox
                    [item]='situation'
                    [current]='searchParams[field] || []'
                    (changed)='toggled($event, situation)'
                ></app-search-filter-checkbox>
            </div>    
        </ng-container>
    </div>
</div>
