<div class='bg' 
    [style.transitionDelay]='transitionDelay + "ms"'
    [class.visible]='visible'
></div>
<div class='response dynamic' *ngIf='dynamic' 
    [style.background]='bgColor'
    [style.border-color]='borderColor'
    [style.transitionDelay]='transitionDelay + "ms"'
    (click)='onClick()'
    [class.small]='smaller'
    [class.visible]='visible'
    role='button'
    [attr.aria-label]='selected ? ("ביטול סינון לפי נושא " + response.name) : ("הפעלת סינון לפי נושא " + response.name)'
    tabindex="0"
    (mouseover)='hover = true' (mouseleave)='hover = false'
>
    <span class='point'>
        <span
            [style.background]='pointBgColor'
            [style.border-color]='pointBorderColor'
            [class.selected]='selected'
            [class.disabled]='disabled'
        ></span>
    </span>
    <span class='name'
        [class.small]='smaller'
        [class.selected]='selected'
        [class.tight]='selected || expandable'
        [class.disabled]='disabled'
        [style.color]='textColor'
        [style.font-weight]='fontWeight'
    >{{response.name + (expanded ? ':': '')}}</span>
    @if (count !== null) {
        <span class='count'>
            @if (plus) {
                (+{{count.toLocaleString()}})
            } @else {
                ({{count.toLocaleString()}})
            }
        </span>
    }
    <span class='icon close' *ngIf='selected'></span>
    <svg class='icon' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"  *ngIf='expandable && !selected'>
        <path d="M11.5 7.5H8.5V4.5C8.5 4.36739 8.44732 4.24021 8.35355 4.14645C8.25979 4.05268 8.13261 4 8 4C7.86739 4 7.74021 4.05268 7.64645 4.14645C7.55268 4.24021 7.5 4.36739 7.5 4.5V7.5H4.5C4.36739 7.5 4.24021 7.55268 4.14645 7.64645C4.05268 7.74021 4 7.86739 4 8C4 8.13261 4.05268 8.25979 4.14645 8.35355C4.24021 8.44732 4.36739 8.5 4.5 8.5H7.5V11.5C7.5 11.6326 7.55268 11.7598 7.64645 11.8536C7.74021 11.9473 7.86739 12 8 12C8.13261 12 8.25979 11.9473 8.35355 11.8536C8.44732 11.7598 8.5 11.6326 8.5 11.5V8.5H11.5C11.6326 8.5 11.7598 8.44732 11.8536 8.35355C11.9473 8.25979 12 8.13261 12 8C12 7.86739 11.9473 7.74021 11.8536 7.64645C11.7598 7.55268 11.6326 7.5 11.5 7.5Z" [attr.fill]='expandColor'/>
    </svg>
</div>
<div class='response' *ngIf='!dynamic' 
    [style.background]='bgColor'
    [style.border-color]='borderColor'
    [class.small]='smaller'
    [class.visible]='visible'
>
    <span class='point'>
        <span
            [style.background]='pointBgColor'
            [style.border-color]='pointBorderColor'
        ></span>
    </span>
    <span class='name'
        *ngIf='isValid(response.name)'
        [class.small]='smaller'
        [style.font-weight]='fontWeight'
        [style.color]='textColor'
        [innerHTML]='response.name'></span>
</div>
