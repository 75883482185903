<div class='widget' [class.results]='state.resultsVisible' [class.map]='!state.resultsVisible' (click)='toggleMap()'>
    <div class='img'></div>
    <div class='msg'>
        <div class='icon'></div>
        <div class='text'>
            @if (state.resultsVisible) {
                תצוגת מפה
            } @else {
                הסתר מפה
            }
        </div>    
    </div>
</div>