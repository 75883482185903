<div *ngIf='layout.desktop()' class='content'
    [class.results]='state.resultsVisible'
    [class.map]='!state.resultsVisible'
>
    <div class='sidebar'>
        <app-srp-area-search-buttons
            [areaSearchState]='areaSearchState'
            [searchState]='searchState'
        ></app-srp-area-search-buttons>
        <app-srp-map-toggle
            [state]='state'
        ></app-srp-map-toggle>
        <app-srp-static-filters
            [areaSearchState]='areaSearchState'
            [filtersState]='filtersState'
        />
    </div>
    <button 
        [ngClass]="{ 'drawer-controller':true,'open-drawer':isDrawerOpen }"
        (click)='toggleDrawer()'
        aria-label="הצג/הסתר מגירת תוצאות"
    >
        <img src='../../assets/img/chevron-left-blue-1.svg' [ngClass]="{ 'flip':isDrawerOpen }"/>
    </button>
    <div class='search-results' [ngClass]="{ 'open':isDrawerOpen }">
        <app-search-results
            class='full'
            [searchParams]='searchParams'
            [active]="serachResultsActive" 
            [didYouMean]='didYouMean'
            [searchState]='searchState'
            (zoomout)='zoomout.emit($event)'
            (visibleCount)='visibleCount.emit($event)'
            (nationalCount)='nationalCount.emit($event)'
            (hoverCard)='hoverCard.emit($event)'
            [areaSearchState]='areaSearchState'
        ></app-search-results>

    </div>
    <div class='extra'>
    </div>
    <div class='overlay' *ngIf='filtersState.active || areaSearchState.searching_'>
        <div class='modal' *ngIf='filtersState.active'>
            <app-search-filters 
                [searchParams]='searchParams'
                [areaSearchState]='areaSearchState'
                [filtersState]='filtersState'
                [searchState]='searchState'
            ></app-search-filters>
        </div>
        <div class='modal' appArrowToTab *ngIf='areaSearchState.searching_'>
            <div class='selection-widget' appArrowToTab>
                <app-area-search-selector [state]='areaSearchState'></app-area-search-selector>
                <app-area-search-selector-results [state]='areaSearchState'></app-area-search-selector-results>
            </div>
        </div>
    </div>
</div>
