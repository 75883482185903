<app-menu-popup title='חסרים לנו שירותים באתר?' subtitle='סייעו לנו להוסיף אותם'>
    <ng-container class='content'>
        <div class='wrapper'>
            <app-menu-popup-accordion title='שירותים המופעלים על ידי עמותה' [open]='section === "ngo"' (selected)='selected("ngo")'>
                <h3>האם שירותי העמותה שלכם מופיעים ב"כל שירות"?</h3>
                <p>אם עדיין לא – זה הזמן לדאוג שיופיעו כך שכל הזקוקים להם ידעו על קיומם ויוכלו ליהנות מהם.</p>
                <p>עליכם להזין את המידע על כל שירותי העמותה באתר "גיידסטאר" והמידע יישאב אוטומטית לאתר "כל שירות".</p>

                <p>לעזרה בתהליך הזנת המידע ראו:</p>

                <p>
                    <a href='https://drive.google.com/file/d/1oglrpfBCbBnP8dhFEoj6ExiZ-L3G-4rU/view?usp=sharing' target="_blank">המדריך של "כל שירות" להזנת מידע</a><br/>
                    <a href='https://drive.google.com/file/d/13fc4OstcWxvfw6_H1v542vee1Gh0gjzj/view?usp=sharing' target="_blank">סרטון הסבר על הזנת מידע בגיידסטאר</a><br/>
                    <a href='https://www.youtube.com/watch?v=nvEqq2dxb9Q' target="_blank">סרטון הסבר של גיידסטאר למה כדאי להזין מידע</a>
                </p>

                <p>שאלות? אנא כתבו אלינו לכתובת <a href='mailto:info@kolsherut.org.il' target="_blank">info&commat;kolsherut.org.il</a></p>
            </app-menu-popup-accordion>
            <app-menu-popup-accordion title='שירותים אחרים' [open]='section === "others"' (selected)='selected("others")'>
                <h3>אילו שירותים מוצגים באתר?</h3>
                <p>
                    אתר כל שירות מציג שירותים ומענים חברתיים שהמדינה, ארגונים וגופים שונים מעמידים לרשותנו, התושבים.
                </p>
                <h3>מה צריך לעשות על מנת שהשירותים שלכם יופיע באתר?</h3>
                <p>
                    כתבו אלינו לכתובת <a href='mailto:info@kolsherut.org.il' target="_blank">info&commat;kolsherut.org.il</a>
                </p>
                <p>
                    אנא ציינו את שמכם, שם הארגון ואילו שירותים חברתיים אתם מספקים.
                    וכמובן, פרטי ההתקשרות שלכם.
                </p>
                <p>
                    תודה, צוות כל שירות.
                </p>
            </app-menu-popup-accordion>
        </div>
    </ng-container>
    @if (section === 'ngo' || section === 'others') {
        <div class='bottom'>
            @if (section === 'ngo') {
                <a class='button' href='https://www.guidestar.org.il/' clickOnReturn target="_blank">
                    <span>לאתר גיידסטאר</span>
                </a>
            }
            @if (section === 'others') {
                <a class='button' href='mailto:info@kolsherut.org.il' clickOnReturn target="_blank">
                    <span>כתבו אלינו</span>
                </a>    
            }
        </div>
    }
</app-menu-popup>