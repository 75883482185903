<button *ngIf='filtersState.active && !filtersState.filtersBarOccupied && layout.desktop()' class='back' (activated)='filtersState.toggle()' aria-label='סגירת הסינון וחזרה לרשימת התוצאות' clickOnReturn></button>
<app-search-filters-button
    [filtersState]='filtersState'
    (activate)='filtersState.toggle()'
    [count]='(filtersState.allFilteredSituations?.length || 0) + (filtersState.currentSearchParams?.filter_responses?.length || 0)'
    interactionEvent='open-filters' 
    [interactionEventWhere]='location.path(false)' 
    [searchParams]='filtersState.searchParams'
></app-search-filters-button>
<ng-container *ngIf='filtersState.filtersBarOccupied'>
    <div class='selected' #scrolledList>
        @for (item of filtersState.staticFilters; track item.key) {
            @if (item.key?.indexOf('human_situation') === 0 && item.key && filtersState.situationsMap[item.key]) {
                <app-situation
                    [situation]='filtersState.situationsMap[item.key]'
                    [selected]='(filtersState.allFilteredSituations || []).indexOf(item.key) > -1'
                    [selectable]='true'
                    [count]='(filtersState.allFilteredSituations || []).indexOf(item.key) > -1 ? null : item.doc_count || 0'
                    [plus]='(filtersState.allFilteredSituations || []).indexOf(item.key) > -1 ? false : item.plus || false'
                    (clicked)='filtersState.toggleSituation(filtersState.situationsMap[item.key])'
                ></app-situation>
            } @else if (item.key?.indexOf('human_service') === 0 && item.key && filtersState.responsesMap[item.key]) {
                <app-response
                    [response]='filtersState.responsesMap[item.key]'
                    [selected]='(filtersState.currentSearchParams.filter_responses || []).indexOf(item.key) > -1'
                    [dynamic]='true'
                    [active]='true'
                    [count]='(filtersState.currentSearchParams.filter_responses || []).indexOf(item.key) > -1 ? null : item.doc_count || 0'
                    [plus]='(filtersState.currentSearchParams.filter_responses || []).indexOf(item.key) > -1 ? false : item.plus || false'
                    (clicked)='filtersState.toggleResponse(filtersState.responsesMap[item.key])'
                    [defaultColors]='true'
                ></app-response>

            }
        }
        @for (response of filtersState.currentSearchParams.filter_responses; track response) {
            @if (filtersState.staticFiltersIds.indexOf(response) === -1) {
                <app-response
                    *ngIf='filtersState.responsesMap[response]'
                    [response]='filtersState.responsesMap[response]'
                    [selected]='true'
                    [dynamic]='true'
                    [active]='true'
                    (clicked)='filtersState.toggleResponse(filtersState.responsesMap[response])'
                ></app-response>
            }
        }
        @for (situation of filtersState.allFilteredSituations; track situation) {
            @if (filtersState.staticFiltersIds.indexOf(situation) === -1) {
                <app-situation
                    *ngIf='filtersState.situationsMap[situation]'
                    [situation]='filtersState.situationsMap[situation]'
                    [selected]='true'
                    (clicked)='filtersState.clearOne(filtersState.situationsMap[situation])'
                ></app-situation>
            }
        }
    </div>
    <div class='controls' [style.width]='scrolledList.clientWidth + "px"' *ngIf='layout.desktop()'>
        <div class='shader right' (click)='scrolledList.scrollBy({behavior: "smooth", left: 100})'
             *ngIf='scrolledList.scrollWidth > scrolledList.clientWidth && scrolledList.scrollLeft < 0'></div>
        <div class='shader left' (click)='scrolledList.scrollBy({behavior: "smooth", left: -100})'
             *ngIf='scrolledList.scrollWidth > scrolledList.clientWidth && scrolledList.scrollLeft > scrolledList.clientWidth - scrolledList.scrollWidth'></div>
    </div>
    <div class='controls' [style.width]='scrolledList.clientWidth + "px"' *ngIf='layout.mobile()'>
        <div class='shader right' *ngIf='scrolledList.scrollWidth > scrolledList.clientWidth && scrolledList.scrollLeft < 0'></div>
        <div class='shader left' *ngIf='scrolledList.scrollWidth > scrolledList.clientWidth && scrolledList.scrollLeft > scrolledList.clientWidth - scrolledList.scrollWidth'></div>
    </div>
</ng-container>